
const dictionaries: { [key: string]: () => Promise<{ [key: string]: string }> } = {
    id: () => import("./dictionaries/id.json").then((module) => module.default),
    en: () => import("./dictionaries/en.json").then((module) => module.default)
};

export const getDictionary = async (locale: string) => {
    const dictionaryFunction = dictionaries[locale];
    if (dictionaryFunction) {
        return dictionaryFunction();
    }
    return {}; 
};
