"use client"
import Link from 'next/link';
import '../../../globals.css';
import Image from 'next/image';
import Hoverdev from './hoverdev'
import FadeUp from '@/lib/dataAos/fadeUp';
import FadeLeft from '@/lib/dataAos/fadeLeft';
import { getDictionary } from '@/dictionaries';
import FadeRight from '@/lib/dataAos/fadeRight';
import { useTheme } from '@mui/material/styles';
import React, { useState, useEffect } from 'react';
import { Container, Box, Grid, Typography } from '@mui/material';
import { useMediaQuery } from '@mui/material';
import { Link as ScrollLink, animateScroll as scroll } from 'react-scroll';

const Page = ({ params }: { params: { lang: string } }) => {
  const theme = useTheme();
  const [dictionary, setDictionary] = useState<{ [key: string]: string }>({});
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    const fetchDictionary = async () => {
      try {
        const result = await getDictionary(params.lang);

        setDictionary(result);
      } catch (error) {
        console.error("Error fetching dictionary:", error);
      }
    };

    fetchDictionary();
  }, [params.lang]);

  return (
    <Box sx={{ width: '100%', backgroundColor: '#18576C', minHeight: '100vh', padding: '120px 0 100px 0' }}>
      <Container maxWidth="lg" >
        <Grid container spacing={2}>
          <Grid item xs={12} lg={8} >
            <Grid container spacing={1} className="boxTitleAbout">
              <Grid item xs={12} lg={7} >
                <FadeUp duration='1000'>
                  <Typography className="titleAbout1">
                    {dictionary.titleAbout1}
                  </Typography>
                </FadeUp>
                <FadeUp>
                  <Box mt={3} sx={{ display: 'flex' }}>
                    {params.lang === 'id' ? (
                      <Typography className="titleAbout2">
                        <span style={{ color: '#fff' }}>Meracik</span>{' '}
                        <span style={{ color: '#78D2F7' }}>rasa</span>{' '}
                        <span style={{ color: '#fff' }}>ke dalam</span>{' '}
                        <span style={{ color: '#78D2F7' }}>komunikasi</span>
                      </Typography>
                    ) : (
                      <Typography className="titleAbout2">
                        <span style={{ color: '#fff' }}>Infuse</span>{' '}
                        <span style={{ color: '#78D2F7' }}>flavor</span>{' '}
                        <span style={{ color: '#fff' }}>into</span>{' '}
                        <span style={{ color: '#78D2F7' }}>communication</span>
                      </Typography>
                    )}
                  </Box>
                </FadeUp>
              </Grid>
            </Grid>
            <Grid item xs={12} >
              <FadeUp duration='1000' >
                <iframe
                  className="iframeYoutube"
                  src="https://www.youtube.com/embed/vo5Q5_IrYpg"
                  title="YouTube video player"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  style={{
                    border: 'none',
                    boxShadow:
                      'rgba(17, 17, 26, 0.1) 0px 1px 0px, rgba(17, 17, 26, 0.1) 0px 8px 24px, rgba(17, 17, 26, 0.1) 0px 16px 48px',
                    borderRadius: '20px',
                  }}
                ></iframe>
              </FadeUp>
            </Grid>
          </Grid>
          <Grid item xs={12} lg={4} sx={{ marginTop: isSmallScreen ? '33px' : 0 }} >
            <Grid item xs={12} >
              <FadeLeft duration='700'>
                <Typography className="textContentAbout" >
                  {dictionary.contentAbout1}
                </Typography>
              </FadeLeft>
            </Grid>
            <Grid item xs={12} >
              <FadeLeft duration='700'>
                <Typography
                  className="textContentAbout">
                  {dictionary.contentAbout2}
                </Typography>
              </FadeLeft>
            </Grid>
            <Grid item xs={12} >
              <FadeUp duration='700'>
                <ScrollLink to='production' smooth={true} duration={1800} style={{ cursor: 'pointer', color: '#A0E0FB', textDecoration: 'none' }}>
                  {dictionary.linkContentAbout}
                </ScrollLink>
              </FadeUp>
            </Grid>
          </Grid>
        </Grid>
      </Container>
      <Box sx={{ marginTop: isSmallScreen ? '100px' : '150px', overflow: 'hidden' }}>
        <Box sx={{ zIndex: 2, position: 'relative' }}>
          <FadeRight duration='700'>
            <Box className="svgContainerVision" >
              {params.lang === 'id' ?
                <img src="/img/visi.png" style={{ height: '100%', width: '100%', objectFit: 'contain' }} alt="" />
                :
                <svg xmlns="http://www.w3.org/2000/svg" className="imgCover" viewBox="0 0 669 160" fill="none">
                  <path fillRule="evenodd" d="M138.054 1.6148L93.3142 138.806C88.403 153.944 80.5987 160 69.1615 160C57.4552 160 50.0546 154.415 44.9415 138.806L0 1.6148H33.908L69.4306 122.59L105.357 1.6148H138.054Z" fill="#0D4051" />
                  <path fillRule="evenodd" d="M156.193 158.385V1.6148H188.083V158.385H156.193Z" fill="#0D4051" />
                  <path fillRule="evenodd" d="M274.572 25.9041C261.924 25.9041 242.75 26.4424 242.75 42.1194C242.75 57.8637 253.514 60.0168 271.545 64.8612C301.55 73.0025 314.468 84.5753 314.468 112.969C314.468 147.553 293.41 160 256.273 160C238.646 160 223.777 157.914 210.995 155.156L214.089 130.059C225.594 131.741 243.086 133.558 255.129 133.558C271.612 133.558 281.703 129.857 281.703 114.113C281.703 99.5122 270.199 96.2153 255.667 91.7746C227.545 83.1623 210.995 74.4827 210.995 44.878C210.995 10.8999 231.043 0 267.104 0C283.722 0 300.81 2.48948 311.978 4.91168L309.086 29.3356C298.927 27.7208 286.278 25.9041 274.572 25.9041Z" fill="#0D4051" />
                  <path fillRule="evenodd" d="M336.643 158.385V1.6148H368.532V158.385H336.643Z" fill="#0D4051" />
                  <path fillRule="evenodd" d="M456.232 160C407.456 160 393.529 136.787 393.529 80C393.529 23.2128 407.456 0 456.232 0C504.941 0 518.868 23.2128 518.868 80C518.868 136.787 504.941 160 456.232 160ZM456.232 133.759C485.902 133.759 486.575 111.96 486.575 80C486.575 48.0404 485.902 26.1732 456.232 26.1732C426.496 26.1732 425.823 48.0404 425.823 80C425.823 111.96 426.496 133.759 456.232 133.759Z" fill="#0D4051" />
                  <path fillRule="evenodd" d="M590.364 20.5887L640.15 116.602V1.6148H668.742V136.114C668.742 152.801 661.746 160 649.501 160C637.862 160 630.529 154.954 622.455 139.344L572.737 42.9941V158.385H544.144V23.8183C544.144 7.13203 551.343 0 563.386 0C574.285 0 582.492 5.44996 590.364 20.5887Z" fill="#0D4051" />
                </svg>
              }
            </Box>
          </FadeRight>
          <FadeRight duration='700'>
            <Box sx={{ position: 'relative' }}>
              <Box className="rectangle22">
                <img src="/img/Rectangle22.png" className="imgCover" style={{ objectPosition: '70% 50%' }} alt="" />
              </Box>
              <Box className="retangle22Containter">
                <Box className="rectangle22Box">
                  <Box sx={{ marginBottom: '30px' }}>
                    <img src='/img/icon/crown.png'
                      style={{
                        width: theme.breakpoints.up('lg') ? '50px' : '45px',
                        height: theme.breakpoints.up('lg') ? '50px' : '45px'
                      }}
                      alt='crown'
                    />
                    {params.lang === 'id' ? (
                      <Typography className="flexoRegular" sx={{ marginTop: '10px', fontSize: theme.breakpoints.up('lg') ? '20px' : '8px' }}>
                        <span style={{ color: '#fff' }}>Untuk menjadi</span>{' '}
                        <span style={{ color: '#78D2F7' }}>pemimpin dalam industri vape</span>{' '}
                        <span style={{ color: '#fff' }}>dan membantu orang menjalani kehidupan yang lebih sehat.</span>{' '}
                      </Typography>
                    ) : (
                      <Typography className="flexoRegular" sx={{ marginTop: '10px', fontSize: theme.breakpoints.up('lg') ? '20px' : '10px' }}>
                        <span style={{ color: '#fff' }}>To be a</span>{' '}
                        <span style={{ color: '#78D2F7' }}>leader in the vape industry</span>{' '}
                        <span style={{ color: '#fff' }}>and help people live a healthier life.</span>{' '}
                      </Typography>
                    )}
                  </Box>
                  <Box >
                    <Image src='/img/icon/trophy.png' width={50} height={50} alt='crown' />
                    {params.lang === 'id' ? (
                      <Typography className="flexoRegular" sx={{ marginTop: '10px', fontSize: theme.breakpoints.up('lg') ? '20px' : '8px' }}>
                        <span style={{ color: '#fff' }}>Menjadi jus</span>{' '}
                        <span style={{ color: '#78D2F7' }}>e-liquid terbaik</span>{' '}
                        <span style={{ color: '#fff' }}>di industri vape.</span>{' '}
                      </Typography>
                    ) : (
                      <Typography className="flexoRegular" sx={{ marginTop: '10px', fontSize: theme.breakpoints.up('lg') ? '20px' : '8px' }}>
                        <span style={{ color: '#fff' }}>To be the</span>{' '}
                        <span style={{ color: '#78D2F7' }}>best e-liquid juice</span>{' '}
                        <span style={{ color: '#fff' }}>in the vape industry.</span>{' '}
                      </Typography>
                    )}
                  </Box>
                </Box>
              </Box>
            </Box>
          </FadeRight>
        </Box>
        <Box className="rectangle33BoxContainer">
          <Box>
            <FadeLeft duration='700'>
              <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Box className="svgContainerMission">
                  {isSmallScreen ?
                    params.lang === 'id' ?
                      <img src="/img/misi1.png" style={{ height: '100%', width: '100%', objectFit: 'contain' }} alt="" />
                      :
                      <svg xmlns="http://www.w3.org/2000/svg" className="imgCover" viewBox="0 0 343 68" fill="none">
                        <path d="M20.5524 8.29461L33.4647 41.1063L46.5179 8.49211C48.7733 2.87772 52.3538 0 56.188 0C62.0802 0 64.3074 2.68023 64.6176 9.76169L67.1549 66.4134H54.299L52.6921 19.2977H52.6357L40.8512 51.5451C39.3569 55.636 36.9888 57.6673 33.5493 57.6673C30.0534 57.6673 27.7134 55.6642 26.1628 51.5451L14.0117 19.4105H13.9554L12.3766 66.4134H0L2.62192 9.70526C2.93204 2.99057 5.27203 0 11.0515 0C14.8575 0 18.2688 2.48274 20.5524 8.29461Z" fill="#D2DDE1" />
                        <path d="M78.1581 66.4134V0.677111H91.5214V66.4134H78.1581Z" fill="#D2DDE1" />
                        <path d="M127.764 10.862C122.464 10.862 114.429 11.0877 114.429 17.6613C114.429 24.2632 118.94 25.166 126.496 27.1973C139.07 30.6111 144.483 35.4637 144.483 47.3696C144.483 61.8711 135.658 67.0905 120.096 67.0905C112.71 67.0905 106.479 66.2159 101.122 65.0591L102.419 54.5357C107.24 55.241 114.57 56.0028 119.617 56.0028C126.524 56.0028 130.753 54.451 130.753 47.8492C130.753 41.727 125.932 40.3446 119.842 38.4825C108.058 34.8712 101.122 31.2318 101.122 18.8181C101.122 4.5705 109.524 0 124.635 0C131.599 0 138.76 1.04388 143.44 2.05954L142.227 12.3009C137.97 11.6237 132.67 10.862 127.764 10.862Z" fill="#D2DDE1" />
                        <path d="M177.823 10.862C172.523 10.862 164.488 11.0877 164.488 17.6613C164.488 24.2632 168.999 25.166 176.555 27.1973C189.129 30.6111 194.542 35.4637 194.542 47.3696C194.542 61.8711 185.717 67.0905 170.155 67.0905C162.769 67.0905 156.538 66.2159 151.181 65.0591L152.478 54.5357C157.299 55.241 164.629 56.0028 169.676 56.0028C176.583 56.0028 180.812 54.451 180.812 47.8492C180.812 41.727 175.991 40.3446 169.901 38.4825C158.117 34.8712 151.181 31.2318 151.181 18.8181C151.181 4.5705 159.583 0 174.694 0C181.658 0 188.819 1.04388 193.499 2.05954L192.286 12.3009C188.029 11.6237 182.729 10.862 177.823 10.862Z" fill="#D2DDE1" />
                        <path d="M203.834 66.4134V0.677111H217.197V66.4134H203.834Z" fill="#D2DDE1" />
                        <path d="M253.948 67.0905C233.508 67.0905 227.672 57.357 227.672 33.5452C227.672 9.73348 233.508 0 253.948 0C274.359 0 280.195 9.73348 280.195 33.5452C280.195 57.357 274.359 67.0905 253.948 67.0905ZM253.948 56.0874C266.381 56.0874 266.663 46.9464 266.663 33.5452C266.663 20.1441 266.381 10.9748 253.948 10.9748C241.487 10.9748 241.205 20.1441 241.205 33.5452C241.205 46.9464 241.487 56.0874 253.948 56.0874Z" fill="#D2DDE1" />
                        <path d="M310.156 8.63317L331.018 48.8931V0.677111H343V57.0749C343 64.0717 340.068 67.0905 334.937 67.0905C330.06 67.0905 326.987 64.9745 323.603 58.4291L302.769 18.0281V66.4134H290.787V9.98739C290.787 2.99057 293.804 0 298.85 0C303.418 0 306.857 2.28525 310.156 8.63317Z" fill="#D2DDE1" />
                      </svg>
                    :
                    params.lang === 'id' ?
                      <img src="/img/misi1.png" style={{ height: '100%', width: '100%', objectFit: 'contain' }} alt="" />
                      :
                      <svg xmlns="http://www.w3.org/2000/svg" className="imgCover" viewBox="0 0 804 160" fill="none">
                        <path fillRule="evenodd" d="M49.0143 19.7813L79.8079 98.032L110.938 20.2523C116.316 6.86291 124.855 0 133.999 0C148.051 0 153.363 6.39192 154.103 23.2801L160.154 158.385H129.495L125.662 46.0219H125.528L97.4234 122.927C93.86 132.683 88.2122 137.527 80.0096 137.527C71.6725 137.527 66.092 132.75 62.394 122.927L33.4158 46.291H33.2813L29.5161 158.385H0L6.25285 23.1455C6.99243 7.13203 12.5729 0 26.3561 0C35.4328 0 43.5682 5.92093 49.0143 19.7813Z" fill="#D2DDE1" />
                        <path fillRule="evenodd" d="M186.394 158.385V1.6148H218.264V158.385H186.394Z" fill="#D2DDE1" />
                        <path fillRule="evenodd" d="M304.698 25.9041C292.057 25.9041 272.896 26.4424 272.896 42.1194C272.896 57.8637 283.653 60.0168 301.672 64.8612C331.659 73.0025 344.568 84.5753 344.568 112.969C344.568 147.553 323.523 160 286.41 160C268.794 160 253.935 157.914 241.161 155.156L244.253 130.059C255.751 131.741 273.232 133.558 285.267 133.558C301.739 133.558 311.825 129.857 311.825 114.113C311.825 99.5122 300.327 96.2153 285.805 91.7746C257.7 83.1623 241.161 74.4828 241.161 44.878C241.161 10.8999 261.197 0 297.235 0C313.842 0 330.919 2.48948 342.08 4.91168L339.189 29.3356C329.037 27.7208 316.397 25.9041 304.698 25.9041Z" fill="#D2DDE1" />
                        <path fillRule="evenodd" d="M424.08 25.9041C411.44 25.9041 392.278 26.4424 392.278 42.1194C392.278 57.8637 403.036 60.0168 421.055 64.8612C451.041 73.0025 463.951 84.5753 463.951 112.969C463.951 147.553 442.906 160 405.792 160C388.177 160 373.318 157.914 360.543 155.156L363.636 130.059C375.133 131.741 392.614 133.558 404.649 133.558C421.122 133.558 431.207 129.857 431.207 114.113C431.207 99.5122 419.71 96.2153 405.187 91.7746C377.083 83.1623 360.543 74.4828 360.543 44.878C360.543 10.8999 380.579 0 416.617 0C433.224 0 450.302 2.48948 461.463 4.91168L458.572 29.3356C448.419 27.7208 435.779 25.9041 424.08 25.9041Z" fill="#D2DDE1" />
                        <path fillRule="evenodd" d="M486.112 158.385V1.6148H517.981V158.385H486.112Z" fill="#D2DDE1" />
                        <path fillRule="evenodd" d="M605.625 160C556.88 160 542.962 136.787 542.962 80C542.962 23.2128 556.88 0 605.625 0C654.303 0 668.221 23.2128 668.221 80C668.221 136.787 654.303 160 605.625 160ZM605.625 133.759C635.276 133.759 635.948 111.96 635.948 80C635.948 48.0404 635.276 26.1732 605.625 26.1732C575.907 26.1732 575.235 48.0404 575.235 80C575.235 111.96 575.907 133.759 605.625 133.759Z" fill="#D2DDE1" />
                        <path fillRule="evenodd" d="M739.671 20.5887L789.425 116.602V1.6148H818V136.114C818 152.801 811.008 160 798.771 160C787.139 160 779.811 154.954 771.742 139.344L722.056 42.9941V158.385H693.481V23.8183C693.481 7.13203 700.675 0 712.71 0C723.602 0 731.805 5.44996 739.671 20.5887Z" fill="#D2DDE1" />
                      </svg>

                  }
                </Box>
              </Box>
            </FadeLeft>
            <FadeLeft duration='700'>
              <Box className="rectangle33">
                <img src="/img/Rectangle33.png" className="imgCover" style={{ objectPosition: isSmallScreen ? '30% 50%' : '0% 50%' }} alt="s" />
              </Box>
              <Grid className="rectangle33Container" container spacing={1}>
                <Grid item lg={8} xs={12} >
                  <Grid container spacing={2} >
                    <Grid item lg={6} xs={12}>
                      <Image src='/img/icon/no-smoking.png' width={50} height={50} alt='crown' />
                      {params.lang === 'id' ? (
                        <Typography className="flexoRegular" sx={{ marginTop: '10px', fontSize: theme.breakpoints.up('lg') ? '20px' : '8px' }}>
                          <span style={{ color: '#1A1A1A' }}>Membantu konsumen untuk beralih dari rokok</span>{' '}
                          <span style={{ color: '#18576C' }}>konvensional ke vaping.</span>{' '}
                        </Typography>
                      ) : (
                        <Typography className="flexoRegular" sx={{ marginTop: '10px', fontSize: theme.breakpoints.up('lg') ? '20px' : '8px' }}>
                          <span style={{ color: '#1A1A1A' }}>Helping customers to switch from</span>{' '}
                          <span style={{ color: '#18576C' }}>conventional cigarettes to vaping.</span>{' '}
                        </Typography>
                      )}
                    </Grid>
                    <Grid item lg={6} xs={12}>
                      <Image src='/img/icon/startup.png' width={50} height={50} alt='crown' />
                      {params.lang === 'id' ? (
                        <Typography className="flexoRegular" sx={{ color: '#1A1A1A', marginTop: '10px', fontSize: theme.breakpoints.up('lg') ? '20px' : '8px' }}>
                          <span style={{ color: '#1A1A1A' }}>Memberikan</span>{' '}
                          <span style={{ color: '#18576C' }}>kontribusi bagi pembangunan</span>{' '}
                          <span style={{ color: '#1A1A1A' }}>nasional dan internasional.</span>{' '}
                        </Typography>
                      ) : (
                        <Typography className="flexoRegular" sx={{ color: '#1A1A1A', marginTop: '10px', fontSize: theme.breakpoints.up('lg') ? '20px' : '8px' }}>
                          <span style={{ color: '#1A1A1A' }}>Contribute to</span>{' '}
                          <span style={{ color: '#18576C' }}>national and international</span>{' '}
                          <span style={{ color: '#1A1A1A' }}>development.</span>{' '}
                        </Typography>
                      )}
                    </Grid>
                    <Grid item lg={6} xs={12}>
                      <Image src='/img/icon/parachute-delivery.png' width={50} height={50} alt='crown' />
                      {params.lang === 'id' ? (
                        <Typography className="flexoRegular" sx={{ color: '#1A1A1A', marginTop: '10px', fontSize: theme.breakpoints.up('lg') ? '20px' : '8px' }}>
                          <span style={{ color: '#1A1A1A' }}>Membuat</span>{' '}
                          <span style={{ color: '#18576C' }}>distribusi produk merata</span>{' '}
                          <span style={{ color: '#1A1A1A' }}>ke seluruh Indonesia.</span>{' '}
                        </Typography>
                      ) : (
                        <Typography className="flexoRegular" sx={{ color: '#1A1A1A', marginTop: '10px', fontSize: theme.breakpoints.up('lg') ? '20px' : '8px' }}>
                          <span style={{ color: '#1A1A1A' }}>Make</span>{' '}
                          <span style={{ color: '#18576C' }}>product distribution evenly distributed</span>{' '}
                          <span style={{ color: '#1A1A1A' }}>throughout Indonesia.</span>{' '}
                        </Typography>
                      )}
                    </Grid>
                    <Grid item lg={6} xs={12}>
                      <Image src='/img/icon/graph-dot.png' width={50} height={50} alt='crown' />
                      {params.lang === 'id' ? (
                        <Typography className="flexoRegular" sx={{ color: '#1A1A1A', marginTop: '10px', fontSize: theme.breakpoints.up('lg') ? '20px' : '8px' }}>
                          <span style={{ color: '#18576C' }}>Meningkatkan kualitas</span>{' '}
                          <span style={{ color: '#1A1A1A' }}>produk menjadi e-liquid terbaik dengan menggunakan teknologi terkini.</span>{' '}
                        </Typography>
                      ) : (
                        <Typography className="flexoRegular" sx={{ color: '#1A1A1A', marginTop: '10px', fontSize: theme.breakpoints.up('lg') ? '20px' : '8px' }}>
                          <span style={{ color: '#18576C' }}>Improving product quality</span>{' '}
                          <span style={{ color: '#1A1A1A' }}>to become the best e-liquid using the latest technology.</span>{' '}
                        </Typography>
                      )}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </FadeLeft>
          </Box>
        </Box>
      </Box>
      <Box className="milesStoneContainer">
        <Hoverdev params={{ lang: params.lang }} />
      </Box>
    </Box>
  )
}

export default Page
