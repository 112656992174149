import { getDictionary } from '@/dictionaries';
import { Box, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';

const Milestone = ({ params }: { params: { lang: string } }) => {
  const [dictionary, setDictionary] = useState<{ [key: string]: string }>({});

  useEffect(() => {
    const fetchDictionary = async () => {
      try {
        const result = await getDictionary(params.lang);
        setDictionary(result);
      } catch (error) {
        console.error("Error fetching dictionary:", error);
      }
    };

    fetchDictionary();
  }, [params.lang]);


  const data = [
    {
      'id': 1,
      'year': '2016',
      'textBox1': '200K',
      'textBox2': 'Sales Qty',
      'icon': '/img/icon/1@4x.png',
      'title': dictionary.milesStoneTitle2,
      'content': dictionary.milesStoneText1
    },
    {
      'id': 2,
      'year': '2017',
      'textBox1': '400K',
      'textBox2': 'Sales Qty',
      'icon': '/img/icon/2@4x.png',
      'title': dictionary.milesStoneTitle3,
      'content': dictionary.milesStoneText2
    },
    {
      'id': 3,
      'year': '2018',
      'textBox1': '700K',
      'textBox2': 'Sales Qty',
      'icon': '/img/icon/3@4x.png',
      'title': dictionary.milesStoneTitle4,
      'content': dictionary.milesStoneText3
    },
    {
      'id': 4,
      'year': '2019',
      'textBox1': '1.9M',
      'textBox2': 'Sales Qty',
      'icon': '/img/icon/4@4x.png',
      'title': dictionary.milesStoneTitle5,
      'content': dictionary.milesStoneText4
    },
    {
      'id': 5,
      'year': '2020',
      'textBox1': '2M',
      'textBox2': 'Sales Qty',
      'icon': '/img/icon/5@4x.png',
      'title': dictionary.milesStoneTitle6,
      'content': dictionary.milesStoneText5
    },
    {
      'id': 6,
      'year': '2021',
      'textBox1': '2.8M',
      'textBox2': 'Sales Qty',
      'icon': '/img/icon/6@4x.png',
      'title': dictionary.milesStoneTitle7,
      'content': dictionary.milesStoneText6
    },
    {
      'id': 7,
      'year': '2022',
      'textBox1': '',
      'textBox2': '',
      'icon': '/img/icon/7@4x.png',
      'title': dictionary.milesStoneTitle8,
      'content': dictionary.milesStoneText7
    },
    {
      'id': 8,
      'year': '2023',
      'textBox1': 'On-Going',
      'textBox2': '',
      'icon': '/img/icon/8@4x.png',
      'title': dictionary.milesStoneTitle9,
      'content': dictionary.milesStoneText8
    },
  ]
  return (
    <Box className="milesStoneBoxContainer">
      <Box sx={{
        width: '2355px', height: '8px', backgroundColor: '#749AA7', borderTopLeftRadius: '5px',
        borderBottomLeftRadius: '5px',
      }}>
      </Box>
      <Box sx={{ width: '2555px', position: 'relative', zIndex: 1002, marginTop: '-95px' }}>
        <Box style={{ display: 'flex', columnGap: '24px', justifyContent: 'end', }}>
          <Box sx={{
            width: "320px",
            minWidth: "160px",
            maxWidth: "480px",
            flexDirection: "column",
            alignItems: "flex-start",
          }}>
            <Typography variant='h5' sx={{ fontWeight: 700, color: '#fff', margin: '0 0 7px 25px' }}>
              2015
            </Typography>
            <Box sx={{ borderRadius: '50%', backgroundColor: '#1A1A1A', width: '112px', height: '112px', display: 'center', alignItems: 'center', justifyContent: 'center' }}>
              {/* <svg xmlns="http://www.w3.org/2000/svg" width="30" height="40" viewBox="0 0 30 40" fill="none">
                <path fillRule="evenodd" clipRule="evenodd" d="M2.15657 0C2.75474 0 3.29604 0.243541 3.68669 0.636875L28.4437 13.0154C28.9308 13.2589 29.2384 13.7567 29.2384 14.3013C29.2384 14.8459 28.9308 15.3437 28.4437 15.5872L4.31313 27.6526V37.8434C4.31313 39.0344 3.34762 40 2.15657 40C0.965538 40 0 39.0344 0 37.8434V2.15657C0 0.965527 0.965538 0 2.15657 0Z" fill="#78D2F7" />
              </svg> */}
              <Box sx={{ width: '45px', height: '45px', overflow: 'hidden' }}>
                <img src="/img/icon/start@4x.png" alt="" style={{ width: '100%', height: 'auto', objectFit: 'contain' }} />
              </Box>
            </Box>
            <Typography variant='h6' sx={{ color: '#fff' }}>
              {dictionary.milesStoneTitle1}
            </Typography>
            <p style={{ color: '#fff' }}>
              {dictionary.milesStoneText0}
            </p>
          </Box>
          {data?.map((item) => (
            <Box
              key={item.id}
              sx={{
                width: "320px",
                minWidth: "160px",
                maxWidth: "480px",
                flexDirection: "column",
                alignItems: "flex-start",
              }}>
              <Typography variant='h5' sx={{ fontWeight: 700, color: '#fff', margin: '0 0 7px 25px' }}>
                {item.year}
              </Typography>
              <Box
                sx={{
                  borderRadius: '50%',
                  backgroundColor: '#1A1A1A',
                  width: '112px',
                  height: '112px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  color: '#78D2F7',
                  flexDirection: 'column',
                  textAlign: 'center',
                }}
              >
                {/* <Typography variant='h6'>
                  {item.textBox1}
                </Typography>
                <Typography variant='subtitle1'>
                  {item.textBox2}
                </Typography> */}
                <Box sx={{ width: '45px', height: '45px', overflow: 'hidden' }}>
                  <img src={item.icon} alt="" style={{ width: '100%', height: 'auto', objectFit: 'contain' }} />
                </Box>
              </Box>
              <Typography variant='h6' sx={{ color: '#fff' }}>
                {item.title}
              </Typography>
              <p style={{ color: '#fff' }}>
                {item.content}
              </p>
            </Box>
          ))}
        </Box>
      </Box>
    </Box>
  )
}

export default Milestone
