"use client"
import AOS from 'aos';
import 'aos/dist/aos.css'
import React, { useEffect } from 'react'

interface Props {
  children: React.ReactNode;
  duration: string;
}

const FadeRight = ({ children, duration }: Props) => {

  useEffect(() => {
    AOS.init({ once: true });
    // AOS.refresh();
  }, []);

  return (
    <div data-aos="fade-right" data-aos-offset="200" data-aos-easing="ease-in-sine" data-aos-duration={duration}>
      {children}
    </div>
  )
}

export default FadeRight
