"use client"
import AOS from 'aos';
import 'aos/dist/aos.css'
import React, { useEffect } from 'react'

interface Props {
  children: React.ReactNode;
  duration?: string;
}

const FadeUp = ({ children, duration }: Props) => {

  // useEffect(() => {
  //   AOS.init({
  //     once: true,
  //     offset: -500,
  //     anchorPlacement: 'center-bottom',
  //     delay: 0
  //   });
  //   AOS.refreshHard();
  // }, []);
  useEffect(() => {
    AOS.init({ once: true });
    AOS.refresh();
  }, []);

  return (
    <div data-aos="fade-up" data-aos-duration={duration} >
      {children}
    </div>
  )
}

export default FadeUp
