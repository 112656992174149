"use client"
import AOS from 'aos';
import 'aos/dist/aos.css'
import React, { useEffect } from 'react'

interface Props {
  children: React.ReactNode;
  duration: string;
}

const FadeLeft = ({ children, duration }: Props) => {

  useEffect(() => {
    AOS.init({ once: true });
    // AOS.refresh();
  }, []);

  return (
    <div style={{ overflow: 'hidden', margin: 0, padding: 0 }}>
      <div data-aos="fade-left" data-aos-offset="300" data-aos-easing="ease-in-sine" data-aos-duration={duration}>
        {children}
      </div>
    </div>
  )
}

export default FadeLeft
